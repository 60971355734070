import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { graphql, Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { kebabCase } from "lodash";
import Layout from "../components/layout";
import MainContent from "../components/maincontent";
import PageBanner from "../components/banner/banner";
import React from "react";
import Seo from "../components/seo";

export default function BlogPost({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <Seo
        title={`Blog | ${frontmatter.title}`}
        description="The clear truth for how anyone is saved from their sins in a world that has rejected God."
        lang="en"
        meta={[
          {
            content: "God, Jesus, Clear, Gospel, Truth, Heaven, Salvation, Saved, Grace, Free, How, Mid-Acts, Romans",
            name: "keywords"
          }
        ]}
      />

      <PageBanner
        hasImage={false}
        hasOverlay={false}
      />

      <MainContent
        hasArticle={true}
      >
        <div className='article-header'>
          <Link className="button button--grey" to="/blog" style={{ marginRight: "0.5rem" }}><FontAwesomeIcon icon={faChevronLeft} /> Blog </Link>
          <h1>
            {frontmatter.title}
          </h1>
        </div>

        <div className='card'>
          <div className='article-width'>
            <article>
              <div
                className="blog-post--content markdown"
                dangerouslySetInnerHTML={{ __html: html }}
              />

              <div className="blog-date">
                Published: {frontmatter.date}
              </div>

              <ul className="blog-list--post-tags" style={{ display: "inline" }}>
                {frontmatter.tags.map((tag) => {
                  return (
                    <li key={`key-${tag}`}>
                      <Link to={`/blog/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  );
                })}
              </ul>
            </article>
          </div>
        </div>
      </MainContent>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        image
        description
        tags
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
